<template>
<div>
	<div class="login-form">
		<h3>{{
			mode == 'unknown' ?
				'Get Started or Sign In' :
				mode == 'login' ? 'Sign in' : mode == 'reset' ? 'Reset Password' : 'Create Account'
		}}</h3>
		
		<p v-if="['unknown', 'register'].includes(mode)" class="legal">By creating an account, you agree to&nbsp;<a href="https://almeta.cloud/terms" target="_blank">Terms&nbsp;of&nbsp;Service</a> and&nbsp;<a href="https://almeta.cloud/privacy-policy" target="_blank">Privacy&nbsp;Policy</a>.</p>
		
		<div class="external-auth-services" v-if="mode != 'reset'">
			<button class="button sign-in-with-google" v-if="!presonalize_sso || highlight_sso.google" @click="ssoLogin('google')">
				<svg class="icon" width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g transform="translate(-713.000000, -692.000000)">
							<g id="google-icon" transform="translate(713.000000, 692.000000)">
								<path d="M14.7,7.67045455 C14.7,7.13863636 14.6522727,6.62727273 14.5636364,6.13636364 L7.5,6.13636364 L7.5,9.0375 L11.5363636,9.0375 C11.3625,9.975 10.8340909,10.7693182 10.0397727,11.3011364 L10.0397727,13.1829545 L12.4636364,13.1829545 C13.8818182,11.8772727 14.7,9.95454545 14.7,7.67045455 L14.7,7.67045455 Z" id="Shape" fill="#4285F4"></path>
								<path d="M7.5,15 C9.525,15 11.2227273,14.3284091 12.4636364,13.1829545 L10.0397727,11.3011364 C9.36818182,11.7511364 8.50909091,12.0170455 7.5,12.0170455 C5.54659091,12.0170455 3.89318182,10.6977273 3.30340909,8.925 L0.797727273,8.925 L0.797727273,10.8681818 C2.03181818,13.3193182 4.56818182,15 7.5,15 L7.5,15 Z" id="Shape" fill="#34A853"></path>
								<path d="M3.30340909,8.925 C3.15340909,8.475 3.06818182,7.99431818 3.06818182,7.5 C3.06818182,7.00568182 3.15340909,6.525 3.30340909,6.075 L3.30340909,4.13181818 L0.797727273,4.13181818 C0.289772727,5.14431818 1.55801298e-13,6.28977273 1.55801298e-13,7.5 C1.55801298e-13,8.71022727 0.289772727,9.85568182 0.797727273,10.8681818 L3.30340909,8.925 L3.30340909,8.925 Z" id="Shape" fill="#FBBC05"></path>
								<path d="M7.5,2.98295455 C8.60113636,2.98295455 9.58977273,3.36136364 10.3670455,4.10454545 L12.5181818,1.95340909 C11.2193182,0.743181818 9.52159091,-1.55431223e-14 7.5,-1.55431223e-14 C4.56818182,-1.55431223e-14 2.03181818,1.68068182 0.797727273,4.13181818 L3.30340909,6.075 C3.89318182,4.30227273 5.54659091,2.98295455 7.5,2.98295455 L7.5,2.98295455 Z" id="Shape" fill="#EA4335"></path>
							</g>
						</g>
					</g>
				</svg>
				Google
			</button>
			
			<button class="button sign-in-with-github" v-if="!presonalize_sso || highlight_sso.github" @click="ssoLogin('github')">
				<font-awesome-icon icon="fa-brands fa-github" class="icon" />
				Github
			</button>
		</div>
		
		<h4 v-if="mode != 'reset' && (!presonalize_sso || highlight_sso.google || highlight_sso.github)">
			<span>or use your email</span>
		</h4>
		
		<form method="post" @submit.prevent="submit" class="email-form">
			<div>
				Email
				<input type="email" name="email" v-model="email" @input="validateEmail" ref="email_input" required autofocus autocomplete="username" />
			</div>
			<div v-if="mode != 'reset'" :class="{ hide: !show_password_input && !password }" :inert="(!show_password_input && !password) == true">
				<template v-if="mode == 'register'">Set your password:</template>
				<template v-else>Password</template>
				<input type="password" name="password" v-model="password" ref="password_input" :disabled="submitting" :autocomplete="mode == 'register' ? 'new-password' : 'current-password'" />
			</div>
			<div v-if="error" class="error">{{ error }}</div>
			<div v-if="mode != 'reset'">
				<input type="submit" class="button" :value="mode == 'unknown' ? 'Continue' : mode == 'login' ? 'Sign in' : 'Create account'" />
			</div>
			<div v-else>
				<input type="submit" class="button" value="Get Password Reset Link" />
				<p class="back-to-sign-in">
					<span class="jslink" @click="mode = 'unknown'; presonalize_sso = false; error = '';">Back to Sign In</span>
				</p>
			</div>
			
			<p v-if="mode == 'unknown' || mode == 'login'" class="reset-password">
				<span class="jslink" @click="mode = 'reset'; error = ''; email_input.focus()">Reset password</span>
			</p>
			
			<div v-if="result">{{ result }}</div>
		</form>
	</div>

	<div class="reviews">
		<div class="review" v-if="show_review_index == 1">
			<blockquote>Our annual subscription to&nbsp;Almeta&nbsp;ML paid for&nbsp;itself in&nbsp;less than a&nbsp;month. We've seen ROAS more than double in&nbsp;retargeting&nbsp;campaigns.</blockquote>
			<cite>Mark Sawyer, Sr. Marketing Manager, Homekilo</cite>
		</div>
	
		<div class="review" v-if="show_review_index == 2">
			<blockquote>We've been using Almeta&nbsp;ML in several of our venture studio projects, and it's been a&nbsp;game-changer. The&nbsp;platform offers powerful predictive analytics and is easy to integrate. We'll keep using it in all our projects.</blockquote>
			<cite>Daniil&nbsp;Kirikov, CEO and&nbsp;Partner, 3F&nbsp;Venture&nbsp;Studio</cite>
		</div>
		
		<div class="review" v-if="show_review_index == 3">
			<blockquote>The&nbsp;ability to&nbsp;predict customer behavior in real-time and&nbsp;utilize these predictions for&nbsp;targeted marketing campaigns on&nbsp;platforms like Google&nbsp;Ads and&nbsp;Facebook&nbsp;Ads is fantastic.</blockquote>
			<cite>Ashik Hameed, Founder, Dorch</cite>
		</div>
		
		<div class="review" v-if="show_review_index == 4">
			<blockquote>Almeta&nbsp;ML gives me the&nbsp;edge I need to&nbsp;compete with&nbsp;larger companies. By&nbsp;understanding customer intent instantly, I can optimize my ad&nbsp;spend and&nbsp;maximize ROI without guesswork.</blockquote>
			<cite>Jackson Tylor, Marketing Specialist</cite>
		</div>
	</div>

</div>
</template>

<script setup lang="ts">
const { $csrfFetch } = useNuxtApp()
const user = useUser()

const email = ref('')
const password = ref('')
const error = ref('')
const result = ref('')
const submitting = ref(false)

const email_input = ref([]) as any
const password_input = ref([]) as any

const email_valid = ref(false)
const show_password_input = ref(false)

const mode = ref('unknown')

const presonalize_sso = ref(false)
const highlight_sso = ref({
	custom: false,
	apple: false,
	github: false,
	google: false,
	facebook: false,
	microsoft: false,
	twitter: false,
})

const show_review_index = ref(1)
const number_of_reviews = 4

function nextReview()
{
	show_review_index.value++
	if (show_review_index.value > number_of_reviews)
		show_review_index.value = 1
	
	setTimeout(nextReview, 7000)
}
setTimeout(nextReview, 5000)

async function submit()
{
	result.value = ''
	error.value = ''
	
	if (!validateEmail())
	{
		error.value = 'Please enter a valid email'
		return
	}
	
	if (mode.value == 'unknown' && !password.value)
		await getSignupStatusByEmail()
	else if (mode.value == 'register')
		await register()
	else if (mode.value == 'reset')
		await resetPassword()
	else
		await login()
}

async function getSignupStatusByEmail()
{
	submitting.value = true
	const data = await $csrfFetch('/api/auth/signup-status-by-email', {
		method: 'POST',
		body: {
			email: email.value,
		},
	})
	
	submitting.value = false
	
	if (data.status == 'exists')
	{
		if (data.local)
			show_password_input.value = true
		else
			show_password_input.value = false
		
		presonalize_sso.value = true
		for (const s of Object.keys(data))
		{
			if (typeof (highlight_sso as any).value[s] === 'boolean')
			{
				(highlight_sso as any).value[s] = true
			}
		}
		
		mode.value = 'login'
	}
	else
	{
		presonalize_sso.value = false
		show_password_input.value = true
		mode.value = 'register'
	}
	
	if (show_password_input.value)
	{
		nextTick(() => {
			password_input.value.focus()
		})
	}
}

async function login()
{
	error.value = ''
	
	submitting.value = true
	
	try
	{
	const data = await $csrfFetch('/api/auth/login', {
		method: 'POST',
		body: {
			email: email.value,
			password: password.value,
		},
	})
	
		if (data.status == 'ok')
			await navigateTo('/', {
				external: true
			})
		else
			throw('error')
	}
	catch (e)
	{
		error.value = 'Incorrect email or password'
	}
	
	submitting.value = false
}

async function register()
{
	error.value = ''
	
	submitting.value = true
	
	const data = await $csrfFetch('/api/auth/register', {
		method: 'POST',
		body: {
			email: email.value,
			password: password.value,
		},
	})
	
	submitting.value = false
	
	if (data.status == 'registered')
		await navigateTo('/account/setup/', {
			external: true
		})
	else if (data.status == 'logged-in')
		await navigateTo('/', {
			external: true
		})
	else
		error.value = 'Error creating account'
}

async function ssoLogin(service: string)
{
	await navigateTo('/login/' + service, {
		external: true
	})
}

async function resetPassword()
{
	error.value = ''
	
	submitting.value = true
	
	const data = await $csrfFetch('/api/auth/reset-password', {
		method: 'POST',
		body: {
			email: email.value,
		},
	})
	
	submitting.value = false
	
	if (data.status == 'sent')
	{
		result.value = 'Please check your email for a password reset link.'
		mode.value = 'login'
	}
	else if (data.status == 'register')
	{
		mode.value = 'register'
		show_password_input.value = true
	}
	else if (data.status == 'exists')
	{
		if (data.local)
			show_password_input.value = true
		else
			show_password_input.value = false
		
		presonalize_sso.value = true
		for (const s of Object.keys(data))
		{
			if (typeof (highlight_sso as any).value[s] === 'boolean')
			{
				(highlight_sso as any).value[s] = true
			}
		}
		
		mode.value = 'login'
	}
	else if (data.status == 'already-sent')
	{
		error.value = 'You have already requested a password reset. Please check your email for the reset link. If you have any questions, please contact us at support@almeta.cloud.'
	}
	else
	{
		error.value = 'Could not reset your password. Please check your email and try again.'
	}
}

function validateEmail()
{
	const re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
	if (re.test(email.value))
		return email_valid.value = true

	return email_valid.value = false
}

onMounted(async () => {
	if (user.value)
		await navigateTo('/', {
			external: true
		})
})

useHead({
	title: 'Sign in to Almeta ML'
})
</script>

<style lang="scss" scoped>
.hide {
	opacity: 0;
	margin: 0 !important;
	max-height: 0;
	overflow: hidden;
}

.button {
	width: 100%;
}

.login-form {
	max-width: 340px;
	margin: 0 auto;
	padding: 40px;
	background: $light-background;
	border: solid 1px $light-border;
	border-radius: 7px;
	
	h3 {
		text-align: center;
		margin: 0;
	}
	
	h4 {
		text-align: center;
		margin: 10px 0;
		position: relative;
		font-weight: normal;
		font-size: 15px;
		border-top: solid 1px #ccc;
		
		span {
			background: $light-background;
			padding: 0 10px;
			position: relative;
			top: -12px;
		}
	}
	
	input {
		margin: 7px 0 20px 0;
		
		&[type=submit] {
			margin: 0;
		}
	}
	
	.reset-password,
	.back-to-sign-in {
		margin: 30px 0 0 0;
		text-align: center;
		font-size: 15px;
	}
}

.legal {
	font-size: 15px;
	margin: 20px 0 10px 0;
	text-align: center;
	line-height: 1.3;
}

.external-auth-services {
	padding: 10px 0 20px 0;
	text-align: center;
	
	/* @include media-breakpoint-down(xs) {
		max-width: none;
		padding-top: 10px;
		border-top: none;
	} */

	button {
		cursor: pointer;
		display: block;
		position: relative;
		margin: 15px auto;
		width: 100%;
		max-width: 212px;
		line-height: 1;
		font-size: 15px;
		white-space: nowrap;
		border: none;
		
		box-shadow: 0px 1px 1px 0px rgba(194, 194, 194, 1);
		
		&.sign-in-with-apple {
			color: #fff;
			background: #000;
			
			&:hover {
				@media (hover: hover) {
					background: #404040;
				}
			}
		}
		
		&.sign-in-with-facebook {
			color: #fff;
			background: #1777F2;
			
			&:hover {
				@media (hover: hover) {
					background: #156bda;
				}
			}
		}
		
		&.sign-in-with-google {
			color: #000;
			background: #fff;
			
			&:hover {
				@media (hover: hover) {
					background: #e6e6e6;
					box-shadow: 0px 1px 1px 0px rgba(180, 180, 180, 1);
				}
			}
		}
		
		&.sign-in-with-github {
			color: #000;
			background: #fff;
			
			&:hover {
				@media (hover: hover) {
					background: #e6e6e6;
					box-shadow: 0px 1px 1px 0px rgba(180, 180, 180, 1);
				}
			}
		}
		
		.icon {
			margin-right: 3px;
		}
		
		svg.icon {
			display: inline-block;
			vertical-align: -.125em;
		}
	}
}

.email-form {
	margin-top: 20px;
	/* background: #ddd; */
}

.error {
	margin: 0 0 20px 0;
	text-align: center;
	color: $danger-color;
	font-weight: 400;
}


.reviews {
	margin: 40px auto;
	max-width: 400px;
	line-height: 1.5;
	text-align: center;
}

.review {
	@media (max-width: $breakpoint-phone) {
		padding: 0 10px;
	}
	
	blockquote {
		margin: 0 0 5px 0;
		
		&:before {
			content: '“';
			/* display: inline-block;
			text-align: right;
			width: 10px;
			margin-left: -10px; */
		}
		
		&:after {
			content: '”';
		}
	}
	
	cite {
		font-style: italic;
		
		&:before {
			content: '— ';
			font-style: italic;
		}
	}
}
</style>